<template>
    <main-layout>
        <v-row align="center">
            <v-col lg="4" sm="6" xs="12" class="mx-auto">
                <v-row>
                    <p class="text-center">Marketing here</p>
                </v-row>
            </v-col>
        </v-row>
    </main-layout>
</template>

<script>
import MainLayout from "@/layout/MainLayout.vue";
import screen from "@/mixins/screen.js";

export default {
    name: "MarketingPage",
    components: {
        MainLayout,
    },
    mixins: [screen],
};
</script>
